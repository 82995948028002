import { BaseParser } from 'app/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/parsers/response-parser';
import { ProvinceAddressComponent } from 'app/models/api/province-address-component';

export class ProvinceAddressComponentParser extends BaseParser {
    parse(data: ServerResponseData, _included: ServerResponseData[], _parsersMap: ParserMap) {
        const item = super.parseBase(ProvinceAddressComponent, data);
        return item;
    }
}
