export enum FilterType {
    'age' = 'age',
    'ageGroups' = 'ageGroups',
    'availability' = 'availability',
    'careType' = 'careType',
    'childrenMaxAge' = 'childrenMaxAge',
    'chores' = 'chores',
    'fosterLocation' = 'fosterLocation',
    'gender' = 'gender',
    'hourlyRate' = 'hourlyRate',
    'languages' = 'languages',
    'lastOnline' = 'lastOnline',
    'maxNumberOfChildren' = 'maxNumberOfChildren',
    'maxDistance' = 'maxDistance',
    'nativeLanguage' = 'nativeLanguage',
    'nonSmoker' = 'nonSmoker',
    'resume' = 'resume',
    'role' = 'role',
}
