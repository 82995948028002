<div class="prompt-content-container">
    <div class="question-number">{{ 'prompts.newFields.questionNumber.format' | translate: { number: 2, count: stepsCount } }}</div>
    <div class="question-title">{{ 'prompts.newFields.parent.skills.title' | translate }}</div>

    @for (skill of skills; track skill.label) {
        <form-checkbox>
            <label [class.active]="skill.selected">
                <span>{{ 'profile.skills.' + skill.label | translate }}</span>
                <input type="checkbox" [(ngModel)]="skill.selected" />
            </label>
        </form-checkbox>
    }
</div>

<div class="buttons-container">
    <button class="btn-bordered btn-previous" (click)="moveBack.emit()">{{ 'main.back' | translate }}</button>
    <button class="btn-solid-neutral btn-next" (click)="onNextPressed()">{{ 'next' | translate }}</button>
</div>
