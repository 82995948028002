<button type="button" [disabled]="loading || disabled" [class.loading]="loading" [class.small]="isSmall" [ngClass]="mainClassName">
    <div class="content">
        @if (iconLeft) {
            <img class="content-icon" src="https://app.sitly.com/releases/20240909132948/assets/images/{{ iconLeft }}.svg" />
        }
        <span class="content-text">
            <ng-content></ng-content>
        </span>
        @if (iconRight) {
            <img class="content-icon" src="https://app.sitly.com/releases/20240909132948/assets/images/{{ iconRight }}.svg" />
        }
    </div>
    <img class="spinner" />
</button>
