import { Injectable, inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslationApiService } from 'app/services/api/translation.api.service';

@Injectable({
    providedIn: 'root',
})
export class WebAppTranslateService implements TranslateLoader {
    private translationApiService = inject(TranslationApiService);

    getTranslation(lang: string) {
        return this.translationApiService.getTranslations(lang, 'web-app');
    }
}
